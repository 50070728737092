import React from "react"
import { localize } from "../../Atoms/Language"

const WhyIsPopularContainerWash = () => (
  <>
    <h2 className="header4">{localize("256")}</h2>
    <p className="body gray600">{localize("257")}</p>
    <p className="body gray600">{localize("258")}</p>
    <p className="body gray600">{localize("259")}</p>
  </>
)

export default WhyIsPopularContainerWash
