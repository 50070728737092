import React from "react"
import { localize } from "../../Atoms/Language"

const ContainerWashContactless = () => (
  <>
    <h2 className="header4">{localize("260")}</h2>
    <p className="body">{localize("261")}</p>
    <p className="body">{localize("262")}</p>
  </>
)

export default ContainerWashContactless
