import React from "react"
import styled from "styled-components"
import CTABanner from "../components/Organisms/CTABanner/CTABanner"
import { GlobalColors } from "../assets/styles/GlobalVariables"
import WhyIsPopularContainerWash from "../components/Organisms/StaticSections/WhyIsPopularContainerWash"
import ContainerWash from "../components/Organisms/StaticSections/containerWash"
import ContainerWashContactless from "../components/Organisms/StaticSections/ContainerWashContactless"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"
import {WithIntl} from "../components/Atoms/withTranslate"

const StyledWrapper = styled.div`
  padding: 80px 0 0;

  & > p {
    color: ${GlobalColors.gray600};

    &:last-of-type {
      margin-bottom: 160px;
    }
  }

  h2 {
    margin-top: 80px;
  }
`

const ContainerWashPage = ({language}) => (
  <>
    <SEO
      lang={language}
      title={localize("263")}
      description={localize("264")}
    />
    <StyledWrapper className="container">
      <ContainerWash/>
      <WhyIsPopularContainerWash/>
      <ContainerWashContactless/>
      <CTABanner language={language}/>
    </StyledWrapper>
  </>
)

export default WithIntl()(ContainerWashPage)
