import React from "react"
import styled from "styled-components"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "carwash3" }) {
      childImageSharp {
        fixed(width: 540) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledImageWithText = styled(ImageWithText)`
  & > div {
    padding-right: 30px;
  }

  .content {
    padding-right: 65px;
  }
`

const ContainerWash = () => {
  const data = useStaticQuery(imageQuery)
  return (
    <>
      <StyledImageWithText fixed={data.file.childImageSharp.fixed} imageAltText={localize("3")}>
        <h1 className="header2">{localize("3")}</h1>
        <div className="content">
          <p className="body">{localize("253")}</p>
          <p className="body">{localize("254")}</p>
        </div>
      </StyledImageWithText>
      <p className="body">{localize("255")}</p>
    </>
  )
}

export default ContainerWash
